import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/en';
import ContactFrom from '../../components/ContactForm';

const Contact = (props) => (
  <Layout bodyClass="page-contact" location={props.location}>
    <SEO title="Contact"/>
    <div className="intro heading">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center mb-2">Contact Us</h1>
          </div>
        </div>
      </div>
    </div>

    <div className="container p-3 p-md-6">
      <div className="row">
        <div className="col-10 offset-1">
          <ContactFrom/>
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
